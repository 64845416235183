import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"

// Components
import SEO from "../components/seo"
import Header from "../components/navigation/header"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Title from "../components/smallTitle"
import Follow from "../components/follow"
import SideNav from "../components/articles"

// Styles
import styles from "../styles/templates/event.module.scss"

export const query = graphql`
  query($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      slug
      subtitle
      featuredImage {
        file {
          url
        }
        fixed {
          src
        }
      }
      images {
        fluid(maxWidth: 500, quality: 100) {
          src
        }
      }
      articleText {
        json
      }
    }
  }
`

const Article = props => {
  console.log(props)

  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        if (!node.data.target.fields) {
          return
        }
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} className={styles.embedded_image} />
      },
      "heading-4": node => {
        return <h4 className={styles.subHeadings}>{node.content[0].value}</h4>
      },
    },
  }
  return (
    <div>
      <Header />
      <SEO
        title={props.data.contentfulArticle.title}
        description={props.data.contentfulArticle.subtitle}
        article={true}
        pathname={`/articles/${props.data.contentfulArticle.slug}`}
        image={`https:${props.data.contentfulArticle.featuredImage.file.url}`}
      />
      <Layout>
        <section className={styles.blogSection}>
          <div className={styles.grid}>
            {/* Main Content */}
            <div className={styles.content}>
              <Title
                text={props.data.contentfulArticle.title}
                subtitle={props.data.contentfulArticle.subtitle}
              />
              {/* Image Grid */}
              <div className={styles.imageGrid}>
                {props.data.contentfulArticle.images.map((image, i) => (
                  <>
                    <div className={styles.image}>
                      <Img fluid={image.fluid} className={styles.image} />
                    </div>
                  </>
                ))}
              </div>
              <div className={styles.content}>
                {documentToReactComponents(
                  props.data.contentfulArticle.articleText.json,
                  options
                )}
                <div className={styles.follow}>
                  <Follow />
                </div>
              </div>
            </div>
            {/* Side Navigation to Other Events */}
            <div className={styles.sideNav}>
              <SideNav
                sidenav={true}
                show={1}
                title={props.data.contentfulArticle.title}
              />
            </div>
          </div>
        </section>
      </Layout>
      <Footer />
    </div>
  )
}

export default Article
