import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import moment from "moment"

// Styles
import styles from "../styles/components/landing/articles.module.scss"

export default function Articles({ show, sidenav, title }) {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle {
        edges {
          node {
            title
            slug
            date(formatString: "MMMM, YYYY")
            shortDescription
            featuredImage {
              fixed(width: 500, quality: 100) {
                ...GatsbyContentfulFixed
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className={sidenav ? styles.sidenav : styles.grid}>
      {data.allContentfulArticle.edges
        .sort((itemA, itemB) => {
          if (sidenav) {
            return 0.5 - Math.random()
          }
          const a = moment(itemA.node.date).unix()
          const b = moment(itemB.node.date).unix()

          return a > b ? -1 : a < b ? 1 : 0
        })
        .filter(item => {
          return item.node.title !== title
        })
        .slice(0, show)
        .map((item, i) => (
          <div className={`${styles.item}`} key={i}>
            <Link to={`/articles/${item.node.slug}`}>
              <Img
                fixed={item.node.featuredImage.fixed}
                alt={item.node.title}
                style={{
                  height: "344px",
                  width: "100%",
                }}
              />
            </Link>

            <div className={styles.content}>
              <h1 className={styles.title}>{item.node.title}</h1>
              <h3 className={styles.date}>{item.node.date}</h3>
              <p className={styles.desc}>{item.node.shortDescription}</p>
              <Link
                to={`/articles/${item.node.slug}`}
                className={styles.btnWrapper}
              >
                <button className={styles.btn}>Read Article</button>
              </Link>
            </div>
          </div>
        ))}
    </div>
  )
}
